import { Check } from '@mui/icons-material';
import { List, Datagrid, Edit, SimpleForm, TextInput, BooleanInput, FormDataConsumer, SelectInput, required} from 'react-admin';
import {
ReferenceArrayInput,
    SelectArrayInput, CheckboxGroupInput
  } from 'react-admin';
  import { Grid } from '@mui/material';

  import { useQuery } from 'react-query';
  import apiClient from "../apiClient"; // Make sure to update the path
  import { useGetOne } from 'react-admin';
  import { useParams } from 'react-router-dom';
  import { useDataProvider } from "react-admin";
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import React, { PureComponent, useState, useEffect} from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Label,LabelList, Legend, ResponsiveContainer } from 'recharts';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import MuiTooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
const Reports = () => {
  const [data, setData] = useState({});
  const [hasData, setHasData] = useState(false);
  const [stageData, setStageData] = useState([]);
  const [shopId, setShopId] = useState(localStorage.getItem('selectedShopId'));
  const [selectedPeriod, setSelectedPeriod] = useState('allTime');
  const [message, setMessage] = useState(null); // Store the message from API
  const [scaleType, setScaleType] = useState('linear'); // Default to 'linear'
  const [allStages, setAllStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState('yamazumi');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isAllTime, setIsAllTime] = useState(false); // Track "All Time" checkbox state
  const fetchData = (currentShopId) => {
    setMessage(null);
    setLoading(true);

    const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] : null;
    const formattedEndDate = endDate ? endDate.toISOString().split('T')[0] : null;
    const apiUrl = `/reports/?shop_id=${currentShopId}${
        formattedStartDate ? `&start_date=${formattedStartDate}` : ''
    }${formattedEndDate ? `&end_date=${formattedEndDate}` : ''}`;

    apiClient.get(apiUrl)
    .then(response => {
        const data = response.data;
        console.log("Data response:", data);

        if (data) {
            if (selectedReport === 'yamazumi') {
                if (!data.yamazumi.average_times || data.yamazumi.average_times.length === 0) { 
                    // Safely check for `average_times` existence and handle the message
                    if (data.message) {
                        setMessage(data.message);
                    } else {
                        setMessage("Not enough data available for the selected criteria.");
                    }
                    setHasData(false);
                } else {
                    setStageData(data.yamazumi.average_times); // Populate chart data
                    setData(data.yamazumi.raw_data); // Store raw data for further use
                    setMessage(null);
                    setHasData(true);
                }
            } 
         else if (selectedReport === 'avgVehiclesDelivered') {
            if (!data.deliveries.average_vehicles_delivered_per_day || data.deliveries.average_vehicles_delivered_per_day === 0) {
                // Safely check for average vehicles delivered data
                if (data.message) {
                    setMessage(data.message);
                } else {
                    setMessage("Not enough data available for the selected criteria.");
                }
                setHasData(false);
            } else {
                setData(data.deliveries); // Store daily deliveries data for further use
                setMessage(null);
                setHasData(true);
            }
        } else if (selectedReport === 'avgCycleTime') {
            if (!data.cycle_times.cycle_times || data.cycle_times.cycle_times.length === 0) {
                // Safely check for `cycle_times` existence and handle the message
                if (data.message) {
                    setMessage(data.message);
                } else {
                    setMessage("Not enough data available for the selected criteria.");
                }
                setHasData(false);
            } else {
                setData(data.cycle_times); // Store cycle times data for further use
                setMessage(null);
                setHasData(true);
            }
        } else if (selectedReport === 'shopEfficiency') {
            if (!data.shop_efficiency.ratio || data.shop_efficiency.ratio === 0) {
                // Safely check for `shop_efficiency` existence and handle the message
                if (data.message) {
                    setMessage(data.message);
                } else {
                    setMessage("Not enough data available for the selected criteria.");
                }
                setHasData(false);
            } else {
                setData(data.shop_efficiency); // Store shop efficiency data for further use
                setMessage(null);
                setHasData(true);
            }
        }
            
            
            
            else {
                if (!data.individual_times || data.individual_times.length === 0) {
                    if (data.message) {
                        setMessage(data.message);
                    } else {
                        setMessage("Not enough data available for the selected criteria.");
                    }
                    setHasData(false);
                } else {
                    setAllStages(data.stages);
                    const processedData = data.stages.map(stage => {
                        const existingData = data.stage_times.find(item => item.stage_name === stage.name);
                        if (existingData) {
                            return {
                                ...existingData,
                                total_time: parseInt(existingData.total_time, 10),
                            };
                        } else {
                            return {
                                stage_name: stage.name,
                                total_time: 0,
                            };
                        }
                    });

                    setStageData(processedData);
                    setData(data);

                    if (data.message) {
                        setMessage(data.message);
                    } else {
                        setMessage(null);
                    }
                    setHasData(true);
                }
            }
            setLoading(false);
        } else {
            console.error("Unexpected data structure:", data);
            setMessage("An unexpected error occurred. Please try again.");
            setHasData(false);
            setLoading(false);
        }
    })
    .catch(error => {
        console.error("Error fetching data:", error);
        setMessage("Failed to fetch data. Please check your connection or try again later.");
        setLoading(false);
        setHasData(false);
    });

  }
  useEffect(() => {
    fetchData(shopId);

    const handleShopChange = () => {
      const newShopId = localStorage.getItem('selectedShopId');
      setShopId(newShopId);
      fetchData(newShopId);
    } 

    // Add event listener to listen for shopChanged event
    window.addEventListener('shopChanged', handleShopChange);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener('shopChanged', handleShopChange);
    };
},[shopId, startDate, endDate,selectedReport]);



  let cycleTimeText = (typeof data.average_cycle_time_seconds !== 'undefined' && data.average_cycle_time_seconds !== null)
  ? `${(data.average_cycle_time_seconds / 3600).toFixed(2)} hours` 
  : 'No repair orders found';

let avgDeliveriesText = (typeof data.average_deliveries_per_day !== 'undefined' && data.average_deliveries_per_day !== null)
  ? `${data.average_deliveries_per_day.toFixed(2)} vehicles/day` 
  : 'No deliveries data found';


  return (
    <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item xs={12} md={4}>
            <div style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '10px', textAlign: 'center' }}>
                <h2>Reports</h2>

<Grid container spacing={3} alignItems="center" justifyContent="center">
    {/* Report Type and Scale Type Dropdowns on Top Line */}
    <Grid item xs={12} style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl variant="outlined" style={{ minWidth: 200 }}>
                <InputLabel id="report-type-label">Report Type</InputLabel>
                <Select
                    labelId="report-type-label"
                    value={selectedReport}
                    onChange={(e) => setSelectedReport(e.target.value)}
                    label="Report Type"
                >
                    <MenuItem value="yamazumi">Yamazumi</MenuItem>
                    <MenuItem value="avgVehiclesDelivered">Average Vehicles Delivered</MenuItem>
                    <MenuItem value="avgCycleTime">Average Cycle Time</MenuItem>
                    <MenuItem value="shopEfficiency">Shop Efficiency</MenuItem>
                </Select>
            </FormControl>
            {selectedReport === 'yamazumi' && (
                <>
                    <FormControl variant="outlined" style={{ minWidth: 200, marginLeft: '16px' }}>
                        <InputLabel id="scale-type-label">Scale Type</InputLabel>
                        <Select
                            labelId="scale-type-label"
                            value={scaleType}
                            onChange={(e) => setScaleType(e.target.value)}
                            label="Scale Type"
                        >
                            <MenuItem value="linear">Proportional Scale</MenuItem>
                            <MenuItem value="log">Logarithmic Scale</MenuItem>
                        </Select>
                    </FormControl>
                    <MuiTooltip title="Proportional Scale is the normal display. Logarithmic is useful when there is a very large difference between two columns.">
                        <IconButton aria-label="info" style={{ marginLeft: '8px' }}>
                            <HelpOutlineIcon color="primary" />
                        </IconButton>
                    </MuiTooltip>
                </>
            )}
        </div>
    </Grid>

    {/* All Time Checkbox */}
    <Grid item xs={3}>
        <FormControlLabel
            control={
                <Checkbox
                    checked={isAllTime}
                    onChange={(e) => {
                        setIsAllTime(e.target.checked);
                        if (e.target.checked) {
                            setStartDate(null); // Clear date values
                            setEndDate(null);
                        }
                    }}
                />
            }
            label="Display Data for All Time"
        />
    </Grid>

    {/* Date Pickers (Hidden if "All Time" is checked) */}
    {!isAllTime && (
        <>
            <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
            </Grid>
        </>
    )}
</Grid>

            </div>
        </Grid>
        <Grid item xs={12} md={8} style={{ minWidth: "75%" }}>
    {loading ? (
        <p>Loading...</p>
    ) : !hasData ? (
        <p>{message}</p>
    ) : selectedReport === 'yamazumi' ? (
        <div style={{ width: '100%', height: '60vh', paddingBottom: '20px'  }}>
            <ResponsiveContainer>
            <BarChart data={stageData} layout="horizontal"    margin={{ bottom: 50, top: 20, left: 20, right: 20 }} // Add sufficient bottom margin
            >
            {/* Add Grid for better readability */}
            <CartesianGrid strokeDasharray="3 3" />

            {/* Y-Axis with Label */}
            <YAxis 
                type="number" 
                scale={scaleType} 
                domain={scaleType === 'log' ? [0.01, 'dataMax'] : [0, 'dataMax + 2']} 
                allowDataOverflow={true}
                tickFormatter={(value) => value.toFixed(2)}
            >
                <Label 
                    value="Average Hours" 
                    angle={-90} 
                    position="insideLeft" 
                    style={{ textAnchor: 'middle', fontSize: '14px',fontWeight: 'bold'}} 
                    dx={-20}
                />
            </YAxis>

            {/* X-Axis with Labels Rotated */}
            <XAxis 
                dataKey="stage" 
                type="category" 
                tick={(props) => {
                    const { x, y, payload } = props; // Access tick props
                    const words = payload.value.split(' '); // Split label into words
            
                    return (
                        <g transform={`translate(${x},${y+10})`}>
                            {words.map((word, index) => (
                                <text
                                    key={index}
                                    x={0}
                                    y={index * 12} // Adjust line spacing
                                    textAnchor="middle"
                                    fontSize={12}
                                >
                                    {word}
                                </text>
                            ))}
                        </g>
                    );
                }}
            
                tickFormatter={(value) => value.split(' ').join('\n')}
                interval={0} // Force all labels to display
            >
       <Label 
        value="Stages" 
        position="bottom" 
        style={{ textAnchor: 'middle', fontSize: '14px',fontWeight: 'bold',marginTop: '20px' }} 
    />
            </XAxis>

            {/* Tooltip */}
            <Tooltip   formatter={(value) => value.toFixed(2)}/>

            {/* Bar Configuration */}
            <Bar 
                dataKey="average_time_hours" 
                fill="#8884d8" 
                name="Average Time (Hours)" 
                label={<LabelList position="insideRight" />}
            />
        </BarChart>

            </ResponsiveContainer>
            </div>
) : selectedReport === 'yamazumi' ? (
    <div>
        <h3>Yamazumi Report</h3>
        <p style={{ fontSize: '20px' }}>Raw Data: {JSON.stringify(data)}</p>
    </div>
) : selectedReport === 'avgVehiclesDelivered' ? (
    <div>
      <h3>Average Vehicles Delivered</h3>
      <p style={{ fontSize: '20px' }}>Average Vehicles Delivered Per Day: {Number(data.average_vehicles_delivered_per_day).toFixed(2)}</p>
    </div>
) : selectedReport === 'avgCycleTime' ? (
    <div>
      <h3>Average Cycle Time</h3>
      <p style={{ fontSize: '20px' }}>Average Cycle Time: {Number(data.average_cycle_time).toFixed(2)} hours</p>
    </div>
) : selectedReport === 'shopEfficiency' ? (
    <div>
        <h3>Shop Efficiency</h3>
        <p style={{ fontSize: '20px' }}>Efficiency Ratio: {Number(data.ratio).toFixed(2)}</p>
        <p style={{ fontSize: '20px' }}>Total Labor Hours: {Number(data.total_labor_hours).toFixed(2)}</p>
        <p style={{ fontSize: '20px' }}>Total Clock Hours: {Number(data.total_clock_hours).toFixed(2)}</p>
    </div>
) : null
}


    </Grid>
  </Grid>
);


};

export default Reports;

